import React from 'react';
import './zero7/zero7.css';
import './App.css';

import { Zero7 } from './zero7/zero7';
import { Marcolimpo } from './Marcolimpo';

import RoutesApp from './routes';

function App() {
  
  return (
    <div className='App'>
      <Zero7>
        <Marcolimpo>
          <RoutesApp />
        </Marcolimpo>
      </Zero7>
    </div>
  )
};

export default App;
