import React from 'react';
import { Link } from 'react-router-dom'

// import BtnNext from '../../components/BtnNext';

const TelaCarrinho: React.FC = () => {
  // Implement the component logic here

  return (
    <section id="tela-carrinho">    
      <h1>ORÇAMENTOS NO CARRINHO</h1>
    
      <Link to='/painel/servicos' className="btn-next btn-adicionar">ADICIONAR SERVIÇO</Link>

      <div id="tela-flutuante">
          <div className="conteudo"></div>
      </div>

      <div id="tela-opcao" className='none'>
          <div>
              <h2>SEU PEDIDO FOI ENVIADO COM SUCESSO</h2>
              <img src="/img/marcolimpo/emoji.png" alt="emoji"/>

              <button className='btn full btn-nav bcolor' value='status'>VER STATUS DO PEDIDO NA PLATAFORMA</button>
              <button className='btn full btn-zap bgreen' value='Olá, gostaria de acompanhar minha solicitação de serviços.'>ACOMPANHAR PEDIDO PELO WHATSAPP</button>
          </div>
      </div>
    </section>
  );
};

export default TelaCarrinho;