import React from 'react';

const TelaNotificacao: React.FC = () => {
  // Implement the component logic here

  return (
    <div>
      Texto
    </div>
  );
};

export default TelaNotificacao;