import React from 'react';
import BtnNext from '../../components/BtnNext';

const TelaAgendar: React.FC = () => {
  // Implement the component logic here

  return (
    <section id='tela-agendar'>
      <h1 id='h1-agendamento'>AGENDAMENTO</h1>
      <div className="calendario z7-agenda">
          <h4 className='label'>DATA DE PREFERÊNCIA</h4>
          <div>
              <div id="header">
              <button className="btn backButton" type="button">◀</button>
                  <div id="monthDisplay"></div>
                  <button className="btn nextButton" type="button">▶</button>
              </div>

              <div id="weekdays">
                  <div>DOM</div><div>SEG</div><div>TER</div><div>QUA</div><div>QUI</div><div>SEX</div><div>SAB</div>
              </div>

               <div id="agenda"></div>{/*<!-- div dinamic --> */}

              <label htmlFor="sem-previsao">
                <input type="checkbox" id="sem-previsao" value='1111-11-11T11:11:11'/>Sem previsão de data</label>
          </div>
          <input type='date' id='agenda-data' hidden required/>
      </div>

      <label htmlFor="agenda-hora">
          PREFERÊNCIA DE HORÁRIO:
          <select id="agenda-hora" required></select>
      </label>

      {/* <script src='/global/agendar/agendar.js?ver=<?php echo$version ?>'></script> */}

      <BtnNext to='TelaOrcamento' className='btn-next'> VOLTAR </BtnNext>
    </section>
  );
};

export default TelaAgendar;