import React, {useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import BtnNext from '../../components/BtnNext';



function z7selOption(valor: string, array: Array<string>): boolean {
    let encontrado = false;

    array.forEach((opt) => {
        if ([valor].includes(opt)) {
            encontrado = true;
            return;
        }
    });

    return encontrado;
}



function gets(params:string) {
  return sessionStorage.getItem(params)
}

function sets(key:string, params:string) {
  return sessionStorage.setItem(key, params)
}

const TelaNovoLocal: React.FC = () => {

  const [nomeServico, setNomeServico] = useState<string>(gets('nomeServico') || '');
  
  const [localTipo, setLocalTipo] = useState<string>(gets('localTipo') || '');
  const [localNome, setLocalNome] = useState<string>(gets('localNome') || 'Selecione um local');
  const [bairro, setBairro] = useState<string>(gets('bairro') || '');

  const [opacity, setOpacity] = useState<string>('opacity');

  const [arrayLocal, setArrayLocal] = useState<Array<any>>([]);

  useEffect(() => {
    setLocalTipo(localTipo);
    setLocalNome(localNome);
    setBairro(bairro);

    sets('localTipo', localTipo);
    sets('localNome', localNome);
    sets('bairro', bairro);
  }, [localTipo, localNome, bairro]);

  function changeLocal(localTp:string) {

    setOpacity('opacity');

    setLocalTipo(localTp);

    if(localTp === 'Residencial') {
      setArrayLocal(['Casa', 'Sobrado', 'Apartamento']);
    } else {
      setArrayLocal(['Mercado', 'Restaurante', 'Escritório']);
    }

    setLocalNome('Selecione um local');

    setOpacity('');
  }
  
  return (
    <section id="tela-novo-local">
      <h1>DADOS DE LOCALIZAÇÃO</h1>

      <div className="novo-endereco">
        <fieldset>
          <legend>LOCAL</legend>
          <label>
            <input type="radio" name="local_tipo" id="Residencial" value="Residencial" required
            checked={localTipo === 'Residencial'}
            onChange={(e) => changeLocal(e.target.value)} /> RESIDENCIAL &ensp;
          </label>
          <label>
            <input type="radio" name="local_tipo" id="Comercial" value="Comercial"
            checked={localTipo === 'Comercial'}
            onChange={(e) => changeLocal(e.target.value)} /> COMERCIAL &ensp;&nbsp;
          </label>
        </fieldset>

        <div id='local-bairro' className={opacity}>
          <p className="p-local">
            <label htmlFor="local_nome">TIPO DO LOCAL</label>
            <select id="local_nome" name="local_nome"
            onChange={(e) => setLocalNome(e.target.value)}

            disabled={opacity === 'opacity'} required >

              <option selected hidden value=''> {localNome}</option>
              {arrayLocal.map(local => ( <option value={local}>{local}</option> ))}

            </select>
          </p>
          <label htmlFor='bairro'>BAIRRO</label>{/* autocomplete='address-level3' */}
          <input id='bairro' name='bairro' value={bairro}
            onChange={(e) => setBairro(e.target.value)}
            disabled={opacity === 'opacity'}
            placeholder='Bairro do local' required />
        </div>
        <br />
        <BtnNext to="TelaSolicitante" className="link" id="usar-end">Usar endereço existente</BtnNext>
        <br />
      </div>
      <Link className='btn-next' to={'/painel/' + nomeServico}>Continuar</Link>
    </section>
  );
};

export default TelaNovoLocal;