import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import logo from '../assets/img/marcolimpo/logoheader.png';
import './styles/Header.css'; // Certifique-se de que o caminho está correto

import { FaArrowLeft, FaUserCircle, FaHome, FaBell, FaShoppingCart, FaSignOutAlt } from 'react-icons/fa';


const Header: React.FC = () => {

  const [UserMenuOpen, setUserMenuOpen] = useState('');

  const toggleUserMenu = () => {
    setUserMenuOpen(!UserMenuOpen ? 'open' : '');
  };

  const handleClickOutside = (event: MouseEvent) => {
    const userMenu = document.querySelector('.user-menu');
    const btnUser = document.querySelector('.btn-user');
    if (userMenu && btnUser && UserMenuOpen) {
      if (!userMenu.contains(event.target as Node) && !btnUser.contains(event.target as Node)) {
        setUserMenuOpen('');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [UserMenuOpen]);

  return (
    <header className="header">
      <nav>
        <div className="logo">
          <Link to='/'>
            <img src={logo} alt="logomarca"/>
          </Link>
          <button className='btn-back'><FaArrowLeft /></button>
        </div>

        <div>
          <button className='btn-user'>
            <FaUserCircle className='icon'/>
          </button>
          <button className='btn-nav btn-home' value='home'>
            <FaHome className='icon'/>
          </button>
          <button className='btn-nav notificacao' value="notificacao">
            <FaBell className='icon'/>
          </button>
          <Link className='btn-nav btn-cart carrinho' to="/painel/carrinho">
            <FaShoppingCart className='icon'/>
          </Link>
        </div>
      </nav>
      <ul className={`user-menu ${UserMenuOpen}`}>
        <button className='btn-nav' value="perfil">
          <FaUserCircle className='icon'/> Minha conta
        </button>

        <a href="/conta/logout/">
          <FaSignOutAlt className='icon'/> Sair
        </a>
      </ul>
    </header>
  );
};

export default Header;