import React, {useState, useEffect, useContext} from 'react';
import { Link } from 'react-router-dom'

import { ViewContext } from '../../zero7/zero7';

import BtnNext from '../../components/BtnNext';


function gets(params:string) {
  return sessionStorage.getItem(params)
}

function sets(key:string, params:string) {
  return sessionStorage.setItem(key, params)
}

const TelaSolicitante: React.FC = () => {

  const nomeServico = (gets('nomeServico') || '');

  return (
    <section id="tela-solicitante">
      <h1>Tela Solicitante</h1>

      <div className="lista-endereco">
        <p>Usar um endereço existente para este orçamento</p>
        <div></div>
      </div>
      <br />

      <BtnNext to='TelaNovoLocal' id="criar-end" className="link">Criar um novo endereço</BtnNext>

      <br /><br />
    
      <Link to={'/painel/' + nomeServico} id="criar-end" className="btn-next">CONTINUAR</Link>

    </section>
  );
};

export default TelaSolicitante;