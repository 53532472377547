import React, { ReactNode, useContext, useEffect } from 'react';
import { ViewContext } from '../zero7/zero7';

import Header from '../components/Header';
import { SideLeft, SideRight } from '../components/Sides';


import TelaServicos from './views/TelaServicos';
import TelaSolicitante from './views/TelaSolicitante';
import TelaNovoLocal from './views/TelaNovoLocal';

const PagServcios: React.FC = () => {
  
  const context = useContext(ViewContext);
  
  if (!context) {
    throw new Error("ViewContext must be used within a ViewProvider");
  }

  // useEffect(() => {
  //   context.setView(context.view);
  // }, [context, context.view, context.setView]);

  console.log(context.view);

  const viewComponents: { [key: string]: ReactNode } = {
    TelaServicos: <TelaServicos />,
    TelaSolicitante: <TelaSolicitante />,
    TelaNovoLocal: <TelaNovoLocal />,
  };

  return (
    <>
      <Header />
    
      <SideLeft />

      <main>
        { viewComponents[context.view] || <TelaServicos /> }
      </main>
    
      <SideRight> </SideRight>
    </>
  );
};
export default PagServcios;