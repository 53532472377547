import React, { useContext, ReactNode } from 'react';
import { ViewContext } from '../zero7/zero7';

import './styles/BtnNext.css';

// type BtnNextProps = Record<string, any>;

interface BtnNextProps {
  children: ReactNode;
  to: string;
  id?: string;
  className?: string;
  // funcao?: Function;
}
/**
 * Componente de botão que muda a View atual.
 * @param to - Próxima Tela para a qual o botão deve mudar.
 * @returns JSX.Element
 */

const BtnNext: React.FC<BtnNextProps> = ({ className='', id, to, children }) => {

  const context = useContext(ViewContext);
  if (!context) {
    throw new Error('BtnNext deve ser usado dentro de um ViewProvider');
  }

  const switchView = (next: string) => {
    context.setView(next);
  };
 
  return (
    <button className={`btn btn-next ${className}`}
    onClick={() => switchView(to)}
    value={to}
    id={id}
    >
      {children}
    </button>
  );
};

export default BtnNext;