import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Import your components here
import PagHome from './Pages/PagHome';
import PagDiarista from './Pages/PagDiarista';
import PagCarrinho from './Pages/PagCarrinho';

import PagServicos from './Pages/PagServicos';
import PagStatus from './Pages/PagStatus';
import PagEnderecos from './Pages/PagEnderecos';
import PagAjuda from './Pages/PagAjuda';

const RoutesApp = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<PagHome />} />
        <Route path="/painel/diarista" element={<PagDiarista />} />
        <Route path="/painel/carrinho" element={<PagCarrinho />} />

        <Route path="/painel/servicos" element={<PagServicos />} />
        <Route path="/painel/status" element={<PagStatus />} />
        <Route path="/painel/enderecos" element={<PagEnderecos />} />
        <Route path="/painel/ajuda" element={<PagAjuda />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesApp;