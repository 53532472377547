import React, { useState, useEffect, useContext } from 'react';

import { ViewContext } from '../../zero7/zero7';

import './styles/TelaServicos.css';

import diaristaImg from '../../assets/img/servicos/diarista.jpg';
import estofadoImg from '../../assets/img/servicos/estofado.jpg';
import rocadaImg from '../../assets/img/servicos/rocada.jpg';

function gets(params:string) {
  return sessionStorage.getItem(params)
}

function sets(key:string, params:string) {
  return sessionStorage.setItem(key, params)
}


function TelaServicos() {
  var [cidades, setCidades] = useState<Array<any>>([]);
  var [servicos, setServicos] = useState<Array<any>>([]);

  // const [idCidade, setIdCidade] = useState<string>(gets('idCidade') || 'Selecione uma cidade');

  var [selectCidade, setSelectCidade] = useState<string>('Selecione uma cidade');

  const [data, setData] = useState(null);


 useEffect(() => {

    setCidades([
      {
        id: 1,
        nome: 'Itanhaém',
      },
      {
        id: 2,
        nome: 'Mongaguá',
      },
      {
        id: 3,
        nome: 'Peruíbe',
      }
    ]);
  
    setServicos([
      {
        id: 1,
        nome: 'diarista',
        version: '1.0',
        imgSrc: diaristaImg
      },
      {
        id: 2,
        nome: 'estofado',
        version: '1.1',
        imgSrc: estofadoImg
      },
      {
        id: 3,
        nome: 'rocada',
        version: '1.2',
        imgSrc: rocadaImg
      }
    ]);

    const fetchData = async () => {
      try {
        const response = await fetch('http://your-php-server.com/index.php'); // Substitua pela sua URL de API
        const jsonData = await response.json(); // Assuming the PHP script returns JSON data
        setData(jsonData);
        console.log(jsonData);
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    //   // Função para buscar dados via AJAX
    //   const fetchServicos = async () => {
    //     try {
    //       const response = await fetch('/api/servicos');
    //       const data = await response.json();
    //       setServicos(data);
    //     } catch (error) {
    //       console.error('Erro ao buscar serviços:', error);
    //     }
    //   };

    //   fetchServicos();
  }, []);

  function capitalizeFirstLetter(text: string): string {
    if (text.length === 0) return text;
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  const ArrayCidades = Array.isArray(cidades) ? cidades.map(cidade => ({
    id: cidade.id,
    nome: cidade.nome,
  })) : [];

  const ArrayServicos = Array.isArray(servicos) ? servicos.map(servico => ({
    id: servico.id,
    nome: servico.nome,
    imgSrc: servico.imgSrc
  })) : [];

  const context = useContext(ViewContext);
  if (!context) {
    throw new Error('BtnNext deve ser usado dentro de um ViewProvider');
  }

  const switchView = (value:string) => {

   // Ajax para verificar se tem end na cidade escolhida e decidir qual tela ira
   context.setView('TelaSolicitante');



    sets('nomeServico', value);
  };



  // $1('#id_cidade').addEventListener('change', function()
  // {
  //     z7sets('id_cidade', this.value);
  //     $2('#tela-servicos fieldset button').forEach(z => { z.disabled = true; });

  //     // Obtém a opção selecionada
  //     const opcaoSelecionada = this.options[this.selectedIndex];
  //     const nomeCidade = z7att(opcaoSelecionada, "cidade");
  //     z7sets('cidade', nomeCidade);

  //     serv_config('cidade', this.value);
  // });


  // disponivel = JSON.parse(response);
  // $2('#tela-servicos fieldset button').forEach(z =>
  // {
  //     if(disponivel.includes(parseInt(z.value)))
  //         z.disabled = false;
  // });
 

  return (
    <section id="tela-servicos">
      <h1>SERVIÇOS</h1>
      <p>
        <label htmlFor="id_cidade">CIDADE</label>
        <select id="id_cidade" name="id_cidade" required>
          <option value="0" hidden selected>Selecione uma cidade</option>
          {ArrayCidades.map(cidade => (
            <option value={cidade.id}>{cidade.nome}</option>
          ))}
        </select>
      </p>
      <fieldset id='field-servicos'>
        {ArrayServicos.map(servico => (
          <button className="btn-servico" onClick={()=>switchView(servico.nome)}>
            <img src={servico.imgSrc} alt={servico.nome} />
          </button>
        ))}
      </fieldset>
    </section>
  );
}

export default TelaServicos;