import React, { ReactNode, useContext, useEffect } from 'react';
import { ViewContext } from '../zero7/zero7';

import Header from '../components/Header';
import { SideLeft, SideRight } from '../components/Sides';


import TelaDiarista from './views/TelaDiarista';
import TelaDiaria from './views/TelaDiaria';

// import TelaFaxina from './views/TelaFaxina';
// import TelaPersonalizado from './views/TelaPersonalizado';
// import TelaAdicional from './views/TelaAdicional';
import TelaAgendar from './views/TelaAgendar';
import TelaOrcamento from './views/TelaOrcamento';

const PagDiarista: React.FC = () => {
  
  const context = useContext(ViewContext);
  
  if (!context) {
    throw new Error("ViewContext must be used within a ViewProvider");
  }

  // useEffect(() => {
  //   context.setView(context.view);
  // }, [context, context.view, context.setView]);

  console.log(context.view);

  const viewComponents: { [key: string]: ReactNode } = {

    TelaDiarista: <TelaDiarista />,
    TelaDiaria: <TelaDiaria />,
    TelaAgendar: <TelaAgendar />

  };

  return (
    <>
      <Header />
    
      <SideLeft />

      <main>
        { viewComponents[context.view] || <TelaDiarista /> }
      </main>
    
      <SideRight> </SideRight>
    </>
  );
};
export default PagDiarista;