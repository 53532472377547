import React, { ReactNode, useContext, useEffect } from 'react';

import Header from '../components/Header';
import { SideLeft, SideRight } from '../components/Sides';
import TelaAjuda from './views/TelaAjuda';

const PagAjuda: React.FC = () => {

  return (
    <>
      <Header />

      <SideLeft />

      <main>
        { <TelaAjuda /> }
      </main>

      <SideRight> </SideRight>
    </>
  );
};
export default PagAjuda;