import React, { useState, useEffect, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import './styles/Sides.css';
import BtnNext from './BtnNext';

const SideLeft: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleSide = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const button = document.querySelector('button.side-left-in') as HTMLButtonElement;
    if (button) {
      button.onclick = toggleSide;
    }
  }, []);

  return (
    <>
      <button className="side-left-in" onClick={toggleSide}>Abrir Menu</button>
      {isOpen && <div className="side-left-out" onClick={toggleSide}></div>}
      <aside className={`side-left ${isOpen ? 'block' : ''}`}>
        <ul>
          <li><Link to="/painel/servicos">Serviços</Link></li>
          <li><Link to="/painel/status">Orçamentos</Link></li>
          <li><Link to="/painel/enderecos">Enderecos</Link></li>
          <li><Link to="/painel/ajuda">Ajuda</Link></li>
        </ul>
      </aside>
    </>
  );
};


interface SideRightProps {
  children: React.ReactNode;
}

const SideRight: React.FC<SideRightProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleSide = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const button = document.querySelector('button.side-right-in') as HTMLButtonElement;
    if (button) {
      button.onclick = toggleSide;
    }
  }, []);

  return (
    <>
      <button className="side-right-in" onClick={toggleSide}>Abrir Menu</button>
      {isOpen && <div className="side-right-out" onClick={toggleSide}></div>}
      <aside className={`side-right ${isOpen ? 'block' : ''}`}>
        {children}
      </aside>
    </>
  );
};

export default SideRight;

export { SideLeft, SideRight };