import React, { createContext, useState, useEffect, ReactNode } from 'react';

function z7gets(params:string) {
  return sessionStorage.getItem(params);


//   let orcEnder = {
//     id_endereco: 0,
//     id_cidade: z7gets('id_cidade'),
//     cidade: z7gets('cidade'),
//     local_tipo: $1('input[name="local_tipo"]:checked').value,
//     local_nome: $1('#local_nome').value,
//     bairro: $1('#bairro').value.trim()
// }
}

// orcamento CONTEXT ------------------------------------------------------------>

// Defina a interface para o tipo de objeto esperado no estado orcamento
interface OrcamentoProps {
  id_orcamento: string;
  id_usuario: number;
  id_config: number;
  id_endereco: number;
  id_funcionario: number;
  id_servico: number;
  detalhes: Record<string, any>; // JSON pode ser representado como um objeto
  valores: Record<string, any>; // JSON pode ser representado como um objeto
  valor_final: number;
  info_admin: string;
  agendamento: Date; // DATETIME pode ser representado como Date
  cadastro: Date; // TIMESTAMP pode ser representado como Date
  modificacao: Date; // TIMESTAMP pode ser representado como Date
  etapa: string; // varchar(20) pode ser representado como string
  pagamento: string; // varchar(20) pode ser representado como string
  forma_pagamento: string; // varchar(10) pode ser representado como string
  valor_pagamento: string; // varchar(10) pode ser representado como string
}

interface InputsValues {
  selectIdCidade: string;
  servico: string;
  pagamento: string; // varchar(20) pode ser representado como string
}

// Crie o contexto com o tipo correto
export const OrcamentoContext = createContext<{
  orcamento: OrcamentoProps | null;
  setOrcamento: React.Dispatch<React.SetStateAction<OrcamentoProps | null>>;
}>({
  orcamento: null,
  setOrcamento: () => {},
});


// Defina a interface para as props do OrcamentoProvider
interface OrcamentoProviderProps { children: ReactNode }

const OrcamentoProvider: React.FC<OrcamentoProviderProps> = ({ children }) => {
  const [orcamento, setOrcamento] = useState<OrcamentoProps | null>(null);

  useEffect(() => {
    setOrcamento({
      id_orcamento: '0',
      id_usuario: 0,
      id_config: 0,
      id_endereco: 0,
      id_funcionario: 0,
      id_servico: 0,
      detalhes: {
        nome: 'Fulano',
        telefone: '13999999999',
        email: '',
      },
      valores: {
        diarias: 1,
        valor_diaria: 100,
        valor_total: 100,
      },
      valor_final: 100,
      info_admin: 'Informações adicionais',
      agendamento: new Date(),
      cadastro: new Date(),
      modificacao: new Date(),
      etapa: 'agendado',
      pagamento: 'pendente',
      forma_pagamento: 'dinheiro',
      valor_pagamento: '100',
    },
    );
    
  }, []);

  return (
    <OrcamentoContext.Provider value={{ orcamento, setOrcamento }}>
      {children}
    </OrcamentoContext.Provider>
  );
};





// VARIAVEIS GLOBAIS ---------------------------------------------------------------->

// Define o tipo literal para os valores possíveis de `mobile`
interface MarcolimpoProps { children: ReactNode; }

// Provedor para os contextos
export const Marcolimpo: React.FC<MarcolimpoProps> = ({ children }) => {
  return (
    <OrcamentoProvider>
      {children}  
    </OrcamentoProvider>
  );
};

