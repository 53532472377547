import React from 'react';

const TelaStatus: React.FC = () => {
  // Implement the component logic here

  return (
<section id="tela-status">

    <h1>STATUS DE SERVIÇOS</h1>

    <div id="tela-flutuante">
        <div className="conteudo"></div>
        <button className='btn-cancelar btn full'>CANCELAR ORÇAMENTO</button>
    </div>
</section>
  );
};

export default TelaStatus;