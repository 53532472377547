import React, { ReactNode, useContext, useEffect } from 'react';

import Header from '../components/Header';
import { SideLeft, SideRight } from '../components/Sides';
import TelaEnderecos from './views/TelaEnderecos';

const PagEnderecos: React.FC = () => {

  return (
    <>
      <Header />

      <SideLeft />

      <main>
        { <TelaEnderecos /> }
      </main>

      <SideRight> </SideRight>
    </>
  );
};
export default PagEnderecos;