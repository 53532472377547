import React, { ReactNode, useContext, useEffect } from 'react';
import { ViewContext } from '../zero7/zero7';

import Header from '../components/Header';
import { SideLeft, SideRight } from '../components/Sides';

import TelaHome from './views/TelaHome';
import TelaNotificacao from './views/TelaNotificacao';
import TelaCarrinho from './views/TelaCarrinho';


// import { log } from 'console';

const PagHome: React.FC = () => {
  
  const context = useContext(ViewContext);
  
  if (!context) {
    throw new Error("ViewContext must be used within a ViewProvider");
  }

  // useEffect(() => {
  //   context.setView(context.view);
  // }, [context, context.view, context.setView]);

  console.log(context.view);

  const viewComponents: { [key: string]: ReactNode } = {
    TelaHome: <TelaHome />,
    TelaNotificacao: <TelaNotificacao />,
    TelaCarrinho: <TelaCarrinho />,
    // TelaPerfil: <TelaPerfil />,
  };

  return (
    <>
      <Header />

      <SideLeft />

      <main>
        { viewComponents[context.view] || <TelaHome /> }
      </main>

      <SideRight> </SideRight>
    </>
  );
};
export default PagHome;