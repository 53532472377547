import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';


// VIEW CONTEXT ------------------------------------------------------------>
// Tipos para os valores dos contextos
interface ViewContextType {
  view: string;
  setView: React.Dispatch<React.SetStateAction<string>>;
}

export const ViewContext = createContext<ViewContextType | undefined>(undefined);

interface ViewProviderProps {
  children: ReactNode;
}

const ViewProvider: React.FC<ViewProviderProps> = ({ children }) => {
  const [view, setView] = useState<string>('TelaHome');

  // useEffect(() => {
  // }, []);

  return (
    <ViewContext.Provider value={{ view, setView }}>
      {children}
    </ViewContext.Provider>
  );
};


// type BtnBackProps = Record<string, any>;

interface BtnBackProps {
  children: ReactNode;
  to: string;
  id?: string;
  className?: string;
}
/**
 * Componente de botão que muda a View atual.
 * @param to - Próxima Tela para a qual o botão deve mudar.
 * @returns JSX.Element
 */

export const BtnBack: React.FC<BtnBackProps> = ({ className='', id, to, children }) => {

  const context = useContext(ViewContext);
  if (!context) {
    throw new Error('BtnBack deve ser usado dentro de um ViewProvider');
  }

  const switchView = (next: string) => {

    //logica de voltar pagina


    context.setView(next);
  
  };
 
  return (
    <button className={`btn btn-back ${className}`}
    onClick={() => switchView(to)}
    value={to}
    id={id}
    >
      {children}
    </button>
  );
};



// MOBILE ---------------------------------------------------------------->
// Define o tipo literal para os valores possíveis de `mobile`
type MobileStatus = 'mobile' | 'desktop';

interface MobileContextType {
  mobile: MobileStatus;
  setMobile: React.Dispatch<React.SetStateAction<MobileStatus>>;
}

export const MobileContext = createContext<MobileContextType | undefined>(undefined);

interface MobileProviderProps {
  children: ReactNode;
}

const MobileProvider: React.FC<MobileProviderProps> = ({ children }) => {
  const [mobile, setMobile] = useState<MobileStatus>('desktop');

  useEffect(() => {
    function handleResize() {
      const isMobile = window.innerWidth < window.innerHeight;
      setMobile(isMobile ? 'mobile' : 'desktop');
      console.log(`Width: ${window.innerWidth}, Height: ${window.innerHeight}, Mobile: ${isMobile ? 'mobile' : 'desktop'}`);
    }

    window.addEventListener('resize', handleResize);

    // Chamar handleResize uma vez para definir o estado inicial
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={mobile}>
      <MobileContext.Provider value={{ mobile, setMobile }}>
        {children}
      </MobileContext.Provider>
    </div>
  );
};



// VARIAVEIS GLOBAIS ---------------------------------------------------------------->

// Define o tipo literal para os valores possíveis de `mobile`

interface Zero7Props { children: ReactNode; }

// Provedor para os contextos
export const Zero7: React.FC<Zero7Props> = ({ children }) => {
  return (
    <ViewProvider>
      <MobileProvider>
          {children}  
      </MobileProvider>
    </ViewProvider>
  );
};
