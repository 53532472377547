import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { tns } from 'tiny-slider/src/tiny-slider';
import 'tiny-slider/dist/tiny-slider.css'; // Importar o CSS do tiny-slider

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAddressCard } from '@fortawesome/free-solid-svg-icons';

import BtnNext from '../../components/BtnNext';


import {
  caixa,
  diarista,
  estofado,
  rocada,
  telhado,
  vidro,
  video,
  oldWhats,
  facebook,
  whatsapp,
  instagram,
  youtube,
  email,
  telefone,
  slide1,
  slide2,
  slide3,
  slide4,
  card1,
  card2,
  card3,
  card4,
  card5
} from '../../assets/telaHome';


import './styles/TelaHome.css';

const TelaHome = () => {

  useEffect(() => {
    var homeSlide = tns({
      container: '.homeSlide',
      items: 1,
      autoplay: true,
      speed: 500,
      autoplayHoverPause: true,
      autoplayTimeout: 2000,
      nav: false,
      navPosition: "bottom",
      autoplayButtonOutput: false,
      controlsContainer: ".sliderNav",
    });

    var cardSlide = tns({
      container: '.cardSlide',
      items: 9,
      slideBy: 4,
      autoplay: true,
      speed: 4000,
      autoplayTimeout: 3000,
      gutter: 80,
      center: true,
      nav: false,
      controls: false,
      autoplayHoverPause: false,
      autoplayButtonOutput: false,
    });
  }, []);

  return (
    <section id='tela-home' className='block'>

      <div className="gradiente">
        <div className="slideBox box-1">
          <ul className="sliderNav">
            <li className="btn prev">❮</li>
            <li className="btn next">❯</li>
          </ul>
          <div className="homeSlide">
            <img src={slide1} alt="slide" />
            <img src={slide2} alt="slide" />
            <img src={slide3} alt="slide" />
            <img src={slide4} alt="slide" />
          </div>
        </div>

        <div className="slogan">
          <cite>
            <span className="cite1">Quer</span>
            <span className="cite2">limpo?</span>&ensp;
            <span className="cite3">Marcolimpo</span>
          </cite>
          <a href="/conta/cadastro/" className="btn hvr1">Cadastrar-se</a>
        </div>
      </div>

      <div className="slideBox box-2">
        <div className="cardSlide">
          <img src={card1} alt="card" />
          <img src={card2} alt="card" />
          <img src={card3} alt="card" />
          <img src={card4} alt="card" />
          <img src={card5} alt="card" />
          <img src={card1} alt="card" />
          <img src={card2} alt="card" />
          <img src={card3} alt="card" />
          <img src={card4} alt="card" />
          <img src={card5} alt="card" />
        </div>
        <p>Parcelamento no cartão de crédito</p>
      </div>

      <Link to='/painel/servicos'>Conheça nossos serviços</Link>

      <div className="caixa">
        <h2>Faxinas e diaristas</h2>
        <div className="x2 reverse">
          <p>
            Diárias de 2h, 4h, 6h e 8h<br />
            Faxina leve, Faxina pesada e Limpeza geral.<br />
            Organização, manutenção e preservação da limpeza<br />
            Para residências, escritórios e comércios.<br />
            Contratação esporádica, semanal, quinzenal ou mensal.<br />
            <Link to='/Servicos' className="btn hvr1">SAIBA MAIS</Link>
          </p>
          <div>
            <img src={diarista} title="Diaristas" alt="Diaristas" />
          </div>
        </div>
      </div>

      <div className="caixa">
        <h2>Limpeza de estofados</h2>
        <div className="x2">
          <div>
            <img src={estofado} title="Estofados" alt="Estofados" />
          </div>
          <p>
            Serviço profissional para limpeza e higienização de estofados de variados tipos<br />
            Sofás, colchões, almofadas, bancos, poltronas...<br />
            <br />
            Responsável pela retirada de toda a sujidade existente no sofá além de combater os fungos, ácaros e bactérias que causam doenças respiratórias e alergias.<br />
            <Link to='/Servicos' className="btn hvr1">SAIBA MAIS</Link>
          </p>
        </div>
      </div>

      <div className="caixa">
        <h2>Limpezas de caixa d'água</h2>
        <div className="x2 reverse">
          <p>
            Após algum tempo de uso, todas as caixas d’água começam a sofrer com o acúmulo de resíduos sólidos.<br />
            Eles chegam pela tubulação que atravessa toda a cidade e vão se amontoando no fundo da caixa.<br />
            O recomendado é que a limpeza das caixas d’água seja feita de seis em seis meses para evitar a concentração de sujeira e proliferação de bactérias.<br />
            <Link to='/Servicos' className="btn hvr1">SAIBA MAIS</Link>
          </p>
          <div>
            <img src={caixa} title="Caixa'dagua" alt="Caixa'dagua" />
          </div>
        </div>
      </div>

      <div className="caixa">
        <h2>Roçadas e aparação de grama</h2>
        <div className="x2">
          <div>
            <img src={rocada} title="Roçadas" alt="Roçadas" />
          </div>
          <p>
            Serviço realizado para a manutenção das áreas de terrenos e quintais, evitando a proliferação de insetos e roedores no local.
            Também tem um cunho estético deixando as áreas mais bonitas e agradáveis.<br />
            <br />
            Roçada é o corte e retirada de vegetação predominantemente composta por vários tipos de capim.<br />
            <br />
            Limpeza é a retirada, em áreas, de lixo/entulhos e resíduos diversos oriundos de diferentes atividades urbanas e/ou da construção civil.
            <Link to='/Servicos' className="btn hvr1">SAIBA MAIS</Link>
          </p>
        </div>
      </div>

      <div className="caixa">
        <h2>Limpeza de vidros comercial e residencial</h2>
        <div className="x2 reverse">
          <p>
            Limpeza técnica básica e limpeza profissional pós-obra<br />

            Técnica Básica: Remoção de sujidades simples, como poeira e sujidades do dia a dia.<br />

            Limpeza Profissional Pós-obra: Remoção de sujidades complexas, como respingos de tintas e argamassas, excesso de pó e grudes de adesivos.<br />

            Existe uma maneira ideal de limpar vidros? Sim, existe. Porém, é preciso levar em consideração o tipo de vidro e o local em que ele está instalado.
            Usar os produtos corretos e os materiais mais adequados garante uma boa limpeza, prolonga a vida útil do vidro e diminui a incidência de riscos sobre eles.
            <Link to='/Servicos' className="btn hvr1">SAIBA MAIS</Link>
          </p>
          <div>
            <img src={vidro} title="Limpeza de vidros" alt="Limpeza de vidros" />
          </div>
        </div>
      </div>

      <div className="parallax x2">
        <button className="btn-zap" value="Olá, encontrei vocês no site e gostaria de mais detalhes.">
          <h2>CHAME AGORA NO WHATSAPP</h2>
          <img src={oldWhats} title="Whatsapp" alt="Whatsapp" />
        </button>

        <video src={video} autoPlay loop muted>
          <style>{'.vsc-controller{display:none}'}</style>
        </video>
      </div>

      <nav className="redes">
        <h3>Redes sociais</h3>
        <div>
          <a href="https://www.facebook.com/marcolimpolimpezas/" target="_blank" rel="noopener noreferrer">
            <img title="Facebook" src={facebook} alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/marcolimpo/" target="_blank" rel="noopener noreferrer">
            <img title="Instagram" src={instagram} alt="Instagram" />
          </a>
          <a href="https://www.youtube.com/channel/UCU_N2ZSkG27DCvdXtE-IHew" target="_blank" rel="noopener noreferrer">
            <img title="YouTube" src={youtube} alt="YouTube" />
          </a>
          <a href="limpezas.html" target="_blank" rel="noopener noreferrer">
            <img title="E-mail" src={email} alt="E-mail" />
          </a>
        </div>
      </nav>

      <a id="curriculo" href="/curriculo/" target="_blank" rel="noopener noreferrer">
        {/* <FontAwesomeIcon icon={faAddressCard} /> */}
        <div>
          <p>Trabalhe com a gente</p>
          <p>Clique para preencher o formulário de qualificação de serviço.</p>
        </div>
      </a>
  
    </section>
  );
};

export default TelaHome;