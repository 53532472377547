import React from 'react';
import BtnNext from '../../components/BtnNext';

const TelaDiaria: React.FC = () => { 
  // Implement the component logic here

  function nextView() {

  }

  return (
    <section id='tela-diaria'>
        <h1>DIÁRIA</h1>
        <p>
            <label htmlFor='operac'>QUANTIDADE DE OPERACIONAIS</label>
            <select id='operac' required>
                <option selected disabled hidden value=''>...</option>
                <option value='1'>1 OPERACIONAL</option>
                <option value='2'>2 OPERACIONAIS</option>
                <option value='3'>3 OPERACIONAIS</option>
                <option value='4'>4 OPERACIONAIS</option>
            </select>
        </p>

        <p>
            <label htmlFor='horas'>QUANTIDADE DE HORAS NECESSÁRIAS</label>
            <select id='horas' required>
                <option selected disabled hidden value=''>...</option>
                <option value='2'>2 HORAS</option>
                <option value='4'>4 HORAS</option>
                <option value='6'>6 HORAS</option>
                <option value='8'>8 HORAS</option>
            </select>
        </p>

        <div className='pre-calculo'>
            <span className='label'>DESCONTO SE MAIS DE 1 OPERACIONAL</span>
            {/* <!-- <p><strong>DESCONTO</strong> <strong className='dct'>R$ 0,00</strong></p> --> */}
            <p className='val-pag'><strong>VALOR TOTAL</strong> <span className='v-cartao'>R$ 0,00</span></p>
            <p className='val-pag'><strong>VIA PIX</strong> <strong className='v-pix'>R$ 0,00</strong></p>
        </div>

        <p>
            <label htmlFor='info_diaria'>FINALIDADE DA DIÁRIA</label>
            <textarea id='info_diaria' name='info_diaria' placeholder='Não é obrigatório'></textarea>
        </p>

        <div>
            <label>ADICIONAR FOTOS</label>
            {/* <div className='z7-file diaria_foto' name='diaria_foto'></div> */}
        </div>

        <BtnNext to='TelaAgendar' className='btn-next'>Continuar</BtnNext>
    </section>
  );
};

export default TelaDiaria;