import React, { useState } from 'react';

import BtnNext from '../../components/BtnNext';

const TelaDiarista: React.FC = () => {

const [proxTela, setProxTela] = useState<string>('');

  return (
    <section id='tela-diarista'>
      <h1>FORMA DE CONTRATAÇÃO</h1>

      <fieldset>
          <label><input type='radio' onClick={()=>setProxTela('TelaDiaria')} name='tipo_servico' value='diaria' required/> DIÁRIA &ensp;</label>
          <label><input type='radio' onClick={()=>setProxTela('TelaFaxina')} name='tipo_servico' value='faxina'/> FAXINA &ensp;</label>
          <label><input type='radio' onClick={()=>setProxTela('TelaPersonalizado')} name='tipo_servico' value='personalizado'/> OUTRO MODO &ensp;</label>
      </fieldset>

      <div>
          <div className='dv-descricao desc-servico block'>
              Aqui você realizará um pré-orçamento que será enviado aos nossos atendentes para avaliação e agendamento do serviço.<br/>
              <br/>
              Selecione os itens para saber sobre eles. Leia atentamente e escolha qual te atender melhor.<br/>
              <br/>
              Para limpeza de fachadas, lavação de muros e outros tipos, retorne ao menu de serviços e selecione "Outros serviços".
          </div>
          {/* <?php print($desc_servico); ?> */}
      </div>

      <BtnNext to={proxTela}>Continuar</BtnNext>
    </section>
  );
};

export default TelaDiarista;